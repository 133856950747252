<script setup lang="ts">
import { defineProps } from "vue";
defineProps<{
  name: string;
  description: string;
  image: string;
}>();

const smallImages = ["dp"];

function getImageSource(src: string) {
  switch (src) {
    case "fd":
      return require("@/assets/images/services-images/floor_decals.jpg");
    case "cr":
      return require("@/assets/images/services-images/chair_rentals.jpg");
    case "pb":
      return require("@/assets/images/services-images/photo_booth.jpg");
    case "bd":
      return require("@/assets/images/services-images/balloon_decor.png");
    case "dp":
      return require("@/assets/images/services-images/dinner_parties.jpg");
    case "w":
      return require("@/assets/images/services-images/weddings.jpg");
    case "g":
      return require("@/assets/images/services-images/graduations.jpg");
    case "a":
      return require("@/assets/images/services-images/anniversaries.jpg");
    case "b":
      return require("@/assets/images/services-images/birthdays.jpg");
    case "che":
      return require("@/assets/images/services-images/church_events.jpg");
    case "c":
      return require("@/assets/images/services-images/couples.jpg");
  }
}
</script>
<template>
  <div class="service-component">
    <div class="service-header">
      <div class="img-container">
        <img :src="getImageSource(image.toLowerCase())" :alt="name" />
      </div>
      <div class="img-overlay"></div>
    </div>
    <div class="service-description">
      <span class="service-name poppins-bold"> {{ name }}</span>
      <p class="service-description-text">{{ description }}</p>
    </div>
  </div>
</template>

<style scoped>
.service-component {
  max-width: 100%;
  width: 320px;
  min-height: 320px;
  display: flex;
  flex-flow: column wrap;
  align-content: flex-start;
  justify-content: flex-start;
  border-radius: 8px;
}

.service-header {
  height: 200px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 10px;
}

.img-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255, 0.2);
  z-index: 2;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.service-description {
  flex: 1;
  padding: 10px;
  text-align: left;
  background: rgb(207, 172, 216, 0.4);
  display: flex;
  flex-flow: column wrap;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.service-name {
  position: relative;
  z-index: 3;
  font-size: 22px;
  color: #5b2460;
  background: rgb(207, 172, 216, 0.8);
  border-radius: 8px;
  padding: 5px 10px;
  text-align: center;
  margin-bottom: 10px;
}

.service-description-text {
  flex: 1;
  width: 100%;
  padding: 0 5px;
}

@media only screen and (max-width: 768px) {
  .service-component {
    width: 340px;
  }

  .service-name {
    font-size: 18px;
  }
}
</style>

<script setup lang="ts">
function hideNavbar() {
  const element = document.getElementById("navbarNav");

  if (element) {
    element.classList.remove("show");
  }
}
</script>

<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <!-- <a class="navbar-brand" href="#">Navbar</a> -->
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <div class="navbar-nav">
          <router-link
            @click="hideNavbar"
            to="/"
            exact
            active-class="active"
            class="nav-link"
            >Home</router-link
          >
          <router-link
            @click="hideNavbar"
            to="/about"
            exact
            active-class="active"
            class="nav-link"
            >About Us</router-link
          >
          <router-link
            @click="hideNavbar"
            to="/services"
            exact
            active-class="active"
            class="nav-link"
            >Services</router-link
          >
          <router-link
            @click="hideNavbar"
            to="/gallery"
            exact
            active-class="active"
            class="nav-link"
            >Gallery</router-link
          >
        </div>
      </div>
    </div>
  </nav>
</template>

<style scoped>
.navbar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #fcf1ff;
  z-index: 999;
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
</style>

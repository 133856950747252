<template>
  <section class="contact-us" id="contact-us">
    <header class="contact-us-header cookie-regular">
      Innovative Designs. Perfect Execution. <br />Memorable Events.
    </header>
    <span class="contact-us-details poppins-regular"
      >Your perfect event starts with a conversation. <br />
      Contact us now to learn more about our services, <br />request a
      consultation, or get a custom quote.</span
    >
    <div class="contact-us-options poppins-bold">
      <div class="option-container">
        <div class="option">
          <a href="tel:5404296381">
            <div class="svg-container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="white"
                class="bi bi-telephone-fill"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                />
              </svg>
            </div>
            <span>(540) 429-6381</span>
          </a>
        </div>
        <div class="option">
          <a href="mailto:innovativeperfectionllc@gmail.com">
            <div class="svg-container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="white"
                class="bi bi-envelope-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z"
                />
              </svg>
            </div>
            <span>innovativeperfectionllc@gmail.com</span>
          </a>
        </div>
        <div class="option">
          <a
            href="https://www.facebook.com/profile.php?id=100083353540328"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div class="svg-container facebook">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#5b2460"
                class="bi bi-facebook"
                viewBox="0 0 16 16"
              >
                <path
                  d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"
                />
              </svg>
            </div>
            <span>Innovative Perfection</span>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.contact-us {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 40px;
  overflow: hidden;
}

.contact-us-header {
  width: 100%;
  margin-bottom: 20px;
  white-space: nowrap;
  font-size: calc(40px + 1vw);
}

.contact-us-header br {
  display: none;
}

.contact-us-details {
  width: 100%;
  white-space: nowrap;
  font-size: 26px;
  margin-bottom: 50px;
  line-height: 1.2;
}

.contact-us-options {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 10px;
}

.option {
  display: flex;
  align-items: center;
  margin: 15px auto;
}

.option a {
  display: flex;
  flex-flow: row nowrap;
  gap: 18px;
  align-items: center;
  color: #5b2460;
  font-size: 22px;
  text-decoration: none;
}

.option .svg-container {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #5b2460;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.svg-container.facebook {
  background: white;
}

@media only screen and (max-width: 750px) {
  .contact-us {
    padding: 40px 20px;
  }

  .contact-us-header {
    font-size: 34px;
    white-space: unset;
    text-align: left;
    line-height: 1;
  }

  .contact-us-details {
    white-space: unset;
    font-size: 18px;
    margin-bottom: 30px;
    text-align: left;
  }

  .contact-us-details br {
    display: none;
  }

  .contact-us-options {
    justify-content: flex-start;
    padding-left: 10px;
  }

  .option .svg-container,
  .svg-container.facebook svg {
    width: 30px;
    height: 30px;
  }

  .svg-container svg {
    width: 16px;
    height: 16px;
  }

  .option a {
    font-size: 14px;
    gap: 10px;
  }
}

@media all and (orientation: portrait) and (min-width: 800px) and (max-width: 1290px) {
  .contact-us-header {
    white-space: unset;
  }

  .contact-us-header br {
    display: unset;
  }
}
</style>
